import React, {useEffect} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PageName} from "../../models/Common";

interface Props {
  setIsShowPaymentForm: (isShow: boolean) => void,
  orderId?: string
}

export const PaymentFailed = ({setIsShowPaymentForm, orderId}: Props) => {
  useEffect(() => {
    // @ts-ignore
    window.dataLayer.push({ 'event': PageName.PAYMENT_FAILED });  }, [])

  return <div className={getClassName('payment-result-container')} id={'payment-failed'}>
    <div className={'payment-result-body'}>
      <div className={'payment-result-image-failed'}/>
      <div className={getClassName('payment-result-title')}>
        Payment Failed
      </div>
      <div className={getClassName('payment-result-description')}>
        Your transaction has failed due to <br/>
        some technical issues.<br/>
        Please try again.
      </div>
    </div>

    <div className={'payment-result-sub-body-container'}>
      <div className={'second-btn'} onClick={() => {setIsShowPaymentForm(true)}}>
        Try Again
      </div>
    </div>

    <div className={'terms-policy-footer'}>
      <a href="https://onelightapps.io/w-terms-of-use" target='_blank'>
        Terms of Use
      </a>
      <div className={'point'}/>
      <a href="https://onelightapps.io/w-privacy-policy" target='_blank'>
        Privacy Policy
      </a>
      <div className={'point'}/>
      <a href={`mailto:support@fltr.pro?subject=FLTR%20Web%20Payment&body=Order%20ID%3A%20${orderId}%0D%0A`}>
          Support
      </a>
    </div>
  </div>;
};

import React, {useEffect, useRef} from 'react';
import { Helmet } from "react-helmet"
import {Product} from "../../models/Product";

interface Props {
  payPalScriptUrl?: string,
  onSuccess: () => void,
  onFail: () => void,
  orderId?: string,
  product?: Product,
}

export const PayPalView = ({payPalScriptUrl, onSuccess, onFail}: Props) => {
  const refPayPallButton = useRef(null);
  useEffect(() => {
    const payPalButton: any = refPayPallButton.current;
    if(payPalButton) {
      payPalButton.addEventListener('order-started-processing', function (e: Error) {
        console.log('order-started-processing',e);
      }, false);
      payPalButton.addEventListener('order-processed', function (e: any) {
        console.log('order-processed', e);
        if(e.detail && e.detail.data && e.detail.data.error && Object.keys(e.detail.data.error).length !== 0) {
          console.log(e.detail.data.error)
          onFail();
        } else {
          onSuccess();
        }
      }, false);
      payPalButton.addEventListener('order-already-processed', function (e: any) {
        console.log('order-already-processed', e);
        if(e.detail && e.detail.data && e.detail.data.error && Object.keys(e.detail.data.error).length !== 0) {
          console.log(e.detail.data.error)
          onFail();
        } else {
          onSuccess();
        }      }, false);
      payPalButton.addEventListener('button-ready', function (e: Error) {
        console.log('button-ready', e)
      }, false);
      payPalButton.addEventListener('button-error', function (e: any) {
        console.log('button-error', e);
        if(!e.detail.error.message.includes('zoid destroyed all components')) {
          onFail();
        }
      }, false);
    }
  }, [])

    return <div style={{width: '100%'}}>
      <div id="paypal-button" ref={refPayPallButton}/>
      <Helmet
          onChangeClientState={(newState, addedTags, removedTags) => console.log(newState, addedTags, removedTags)}
      >
        <script type="text/javascript"
                src={payPalScriptUrl}
                data-label="paypal"
                data-color="white"
                data-shape="pill"
                data-height="48"
        />
      </Helmet>
    </div>;
};

import {GeneralActionType} from "../actions/general";
import {SolidgateMerchantData} from "../models/Solidgate";
import {Product} from "../models/Product";
import {getItem, saveItem} from "../utils/LocalStoargeUtill";

interface AppState {
  isLogged: boolean,
  isLoadingMerchantDate: boolean,
  merchantData?: SolidgateMerchantData,
  appInstallDeepLink?: string,
  payPalScriptUrl?: string,
  redirectAppUrl?: string,
  isShowPaymentForm: boolean,
  error?: any,
  selectedProduct?: Product | null,
  orderId?: string | null,
  isAllowedCookies: boolean
}

const getInitialState = (): AppState => ({
  isLogged: false,
  isLoadingMerchantDate: false,
  merchantData: undefined,
  appInstallDeepLink: undefined,
  payPalScriptUrl: undefined,
  isShowPaymentForm: false,
  error: null,
  selectedProduct: null,
  redirectAppUrl: undefined,
  orderId: undefined,
  isAllowedCookies: getItem('isAllowedCookies', false)
});

export default function appState(state = getInitialState(), action: any) {
  switch (action.type) {
    case GeneralActionType.SET_IS_LOADING_MERCHANT_DATA:
      return Object.assign({}, state, {
        isLoadingMerchantDate: action.isLoading,
      });
    case GeneralActionType.SAVE_MERCHANT_DATA:
      return Object.assign({}, state, {
        merchantData: action.merchantData,
        payPalScriptUrl: action.payPalScriptUrl,
        redirectAppUrl: action.redirectAppUrl,
        orderId: action.orderId,
        isShowPaymentForm: true,
        isLoadingMerchantDate: false
      });

    case GeneralActionType.SELECT_PRODUCT:
      return Object.assign({}, state, {
        selectedProduct: action.product,
      });

    case GeneralActionType.SET_IS_SHOW_PAYMENT_FORM:
      return Object.assign({}, state, {
        isShowPaymentForm: action.isShow,
      });

    case GeneralActionType.SET_IS_LOADING_PAYPALL_SCRIPT:
      return Object.assign({}, state, {
        isLoadingPayPallScript: action.isLoading,
      });

    case GeneralActionType.SET_IS_ALLOW_COOKIES:
      saveItem(action.isAllow, 'isAllowedCookies');
      return Object.assign({}, state, {
        isAllowedCookies: action.isAllow,
      });

    default:
      return state;
  }
}

import React, {useEffect} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PageName} from "../../models/Common";

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
  startQuiz: () => void
}

type Props = StateProps & DispatchProps & OwnProps;

export const QuizStartScreen = ({startQuiz}: Props) => {
  useEffect(() => {
    // @ts-ignore
    window.dataLayer.push({ 'event': PageName.QUIZ_START });
  }, [])

  return <div className={getClassName('quiz-start-page-container')}>
    <div className={getClassName('quiz-start-background-container')}/>
    <div className={getClassName('title-text')} style={{textAlign: 'center'}}>
      Edit photo & video<br/>  with top-rated filters
    </div>
    <div className={getClassName('second-text')} style={{marginTop: '16px', textAlign: 'center'}}>
      Start your creative journey with a<br/> short quiz so we can personalize the<br/> experience for you
    </div>
    <button className={getClassName('general-btn')} style={{marginTop: '32px'}}
            onClick={startQuiz}
    >
      Let's go
      <div className={'arrow-btn'}/>
    </button>
  </div>;
}

import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PageName} from "../../models/Common";
import Cookies from "../general/Cookies";
import {QuizResources} from "../../models/Resources";
// @ts-ignore
import Slider from 'react-input-slider';
import {useHistory} from "react-router-dom";

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
  startQuiz: () => void
}

type Props = StateProps & DispatchProps & OwnProps;

export const QuizMainScreen = ({startQuiz}: Props) => {
  const refContainer = useRef(null);
  const [state, setState] = useState({ x: 50, y: 10 });
  const refSlider = useRef(null);
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    window.dataLayer.push({ 'event': PageName.QUIZ_MAIN });
  }, [])

  const renderBeforeAfter = () => {
    return <div className={'quiz-main-before-after'}>
      <img className={getClassName('quiz-iphone-img')} src={QuizResources.iPhone.blobSrc}/>

      <div ref={refContainer} className={getClassName('quiz-main-before-after-container')}>
      <div className={getClassName('quiz-main-before-after-subcontainer')}>
          <img className={'image-before slider-image'}  src={QuizResources.quizMainBefore.blobSrc}/>
          <img className={'image-after slider-image'} src={QuizResources.quizMainAfter.blobSrc}/>
      </div>
        <Slider
            axis="x"
            x={state.x}
            onChange={({ x }) => {
              setState(state => ({ ...state, x }))
              // @ts-ignore
              refContainer.current.style.setProperty('--position', `${x}%`);
            }}
            styles={{
              track: {
                opacity: 0,
                width: '100%',
                height: '100%',
                zIndex: 10,
                position: "absolute"
              },
              active: {
                opacity: 0
              },
              thumb: {
                opacity: 0
              },
              disabled: {
                opacity: 0
              }
            }}
        />
{/*      <input
          type="range"
          min="0"
          max="100"
          value="50"
          aria-label=""
          className="slider"
          ref={refSlider}
      />*/}
      <div className="slider-line" aria-hidden="true"></div>
      <div className="slider-button" aria-hidden="true"></div>
    </div>
    </div>
  }

  return <div className={getClassName('quiz-main-page-container')}>
    <div className={getClassName('quiz-main-background-container')}/>
    <div className={getClassName('main-text')} style={{textAlign: 'center', paddingTop: '64px'}}>
      1500+ Presets<br/>
      for Lightroom
    </div>
    <div className={getClassName('quiz-main-subtitle')} style={{marginTop: '8px', textAlign: 'center'}}>
      Photo & Video Filters
    </div>
    {renderBeforeAfter()}
    <div className={'quiz-main-line'}/>
    <button className={getClassName('general-btn')} style={{marginTop: '16px'}}
            onClick={startQuiz}
    >
      Continue
      <div className={'arrow-btn'}/>
    </button>
    <img width={'311px'} height={'73px'} style={{paddingTop: '20px'}} src={QuizResources.quizMainSocialProof.blobSrc}/>

    <div className={'terms-policy-footer'} style={{marginTop: '16px', paddingBottom:'72px'}}>
      <a href="https://onelightapps.io/w-terms-of-use" target='_blank'>
        Terms of Use
      </a>
      <div className={'point'}/>
      <a href="https://onelightapps.io/w-privacy-policy" target='_blank'>
        Privacy Policy
      </a>
      <div className={'point'}/>
      <a href={`mailto:support@onelightapps.io`}>
        Support
      </a>
    </div>
    <Cookies/>
  </div>;
}

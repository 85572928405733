export interface SolidgateMerchantData {
    paymentIntent: string,
    merchant: string,
    signature: string,
}

export enum SolidgateErrorType {
    Fail = 'solidgate_fail',
    Error = 'solidagate_error'
}

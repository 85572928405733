export interface Question {
    question: string;
    answer: string;
    isActive: boolean;
}

export const questions: Question[] = [
    {isActive: false, question: 'How to apply Lightroom presets to photos and videos?', answer: 'If you have both FLTR and Lightroom apps installed on your device, you can select a preset in the FLTR app and open it in the Lightroom app. Once you open the preset, you can apply the settings to your photo or video by copying and pasting them. The FLTR app provides step-by-step instructions in both text and video formats, making it easy for users to use presets.'},
    {isActive: false, question: 'How do I save and organize the presets that I like to use frequently?', answer: 'If you want to easily access your frequently used presets, simply add them to your Favorites or use the Search by tag feature to quickly find them later on.'},
    {isActive: false, question: 'How does the auto-renewable subscription model work?', answer: 'The FLTR offers auto-renewable subscriptions to access premium features. This means that when you subscribe, you will be charged on a recurring basis (e.g., monthly, yearly) until you cancel your subscription. The subscription will automatically renew unless you turn off auto-renewal in your account settings at least 24 hours before the end of the current subscription period. You can manage your subscription and turn off auto-renewal at any time by going to your account settings in the App Store.'},
    {isActive: false, question: 'How can I cancel my subscription at any time?', answer: 'To cancel your subscription at any time, go to your Apple ID settings in the App Store and select the subscription you want to cancel. Keep in mind that the subscription will automatically renew unless you turn off auto-renewal in your account settings at least 24 hours before the end of the current subscription period. You will still have access to premium features until the end of your current subscription period.'},
    {isActive: false, question: 'How can I request a refund?', answer: 'If you would like to request a refund for your purchase, you can reach us through the \'Contact Us\' section in the app settings or via support@onelightapps.io, where we will provide you with all the details.'}
]

import React, {useEffect, useState} from 'react';
import {Resource, ResourceType} from '../../models/Resources';
import {PaywallOffer} from './PaywallOffer';
import {PaywallBody} from './PaywallBody';
import {Header} from '../general/Header';
import {preloadResource} from '../../utils/URLUtils';
import MDSpinner from 'react-md-spinner';
import {spinnerColor} from '../../utils/StyleUtils';
import {HeaderOnboarding} from "../general/HeaderOnboarding";

const Paywall = ({}) => {
    const [isLoadingResources, setIsLoadingResources] = useState(false)
  const [stepNumber, setStepNumber] = useState(2)

    useEffect(() => {
        const loadResources = (resource: Resource) => {
            return new Promise((resolve, reject) => {
              if(resource.type === ResourceType.VIDEO) {
                 resolve(null);
              } else {
                preloadResource(resource.src).then((src) => {
                  resource.blobSrc = src;
                  resolve(src);
                })
              }
            })
        }

/*         Promise.all(Object.values(PaywallResources).map(resource => loadResources(resource)))
            .then(() => setIsLoadingResources(false))
            .catch(err => console.log("Failed to load images", err)) */
    }, [])
  if(isLoadingResources) {
      return <div className={'paywall-page-container'}>
          <HeaderOnboarding/>
          <div className='spinner-container' style={{height: '100VH'}}>
              <MDSpinner size={100} singleColor={spinnerColor}/>
          </div>
      </div>
  }
  return (
      <div className={'paywall-page-container'}>
        <PaywallOffer/>
        <PaywallBody/>
      </div>
  );
};

export default Paywall;

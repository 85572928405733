import React, {useEffect} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PageName} from "../../models/Common";
import {logoutApp} from "../../utils/AuthUtill";
import uuid from "uuid";

interface Props {
  redirectAppUrl?: string,
  orderId?: string,
}

export const PaymentSuccessful = ({redirectAppUrl, orderId}: Props) => {
  useEffect(() => {
    // @ts-ignore
    window.dataLayer.push({'event': PageName.PAYMENT_SUCCESS});
    logoutApp(uuid.v4());
  }, [])

  const openAppUrl = () => {
    window.open(redirectAppUrl ? redirectAppUrl : "https://fltr.go.link/?adj_t=17641ees_178pi2hn", "_blank");
  }

  return <div className={getClassName('payment-result-container')} id={'payment_successful'}>
    <div className={'payment-result-body'}>
      <div className={'payment-result-image-success'}/>
      <div className={getClassName('payment-result-title')}>
        Payment Successful
      </div>
      <div className={getClassName('payment-result-order-id')}>
        Order ID: {orderId}
      </div>
{/*      <div className={getClassName('payment-result-get-receipt')}>
        Get Receipt
      </div>*/}
    </div>

    <div className={'payment-result-sub-body-container'}>
      <div className={getClassName('payment-result-description')}>
        Thank you for choosing the FLTR! <br/>
        Get the app and enjoy your journey!
      </div>
      <button className={getClassName('general-btn')} onClick={openAppUrl}>
        Download FLTR
        <div className={'arrow-btn'}/>
      </button>
      <div style={{display: 'flex', justifyContent: 'center', gap: '12px'}}>
        <div className={'google-store-image'} onClick={openAppUrl}/>
        <div className={'apple-store-image'} onClick={openAppUrl}/>
      </div>
    </div>

    <div className={'terms-policy-footer'}>
      <a href="https://onelightapps.io/w-terms-of-use" target='_blank'>
          Terms of Use
      </a>
      <div className={'point'}/>
      <a href="https://onelightapps.io/w-privacy-policy" target='_blank'>
          Privacy Policy
      </a>
      <div className={'point'}/>
      <a href={`mailto:support@fltr.pro?subject=FLTR%20Web%20Payment&body=Order%20ID%3A%20${orderId}%0D%0A`}>
          Support
      </a>
    </div>
  </div>;
};

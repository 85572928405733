import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
// @ts-ignore
import {questions} from "../../models/Questions";

export const Questions = () => {
  const [questionsState, setQuestionsState] = useState([...questions]);

  return <div className={getClassName('questions-screen-container')} id={'faq'}>
    <div className={getClassName('title')}>
      Frequently Asked<br/> Questions
    </div>
    <div className={getClassName('questions-container')}>
      {
        questionsState.map((question, index) => {
          return <div className={'question-container'}>
            <div className={'question-header'} onClick={() => {
              const newState = [...questionsState];
              newState[index].isActive = !question.isActive;
              setQuestionsState(newState);
            }}>
              <div className={'question-title' + (question.isActive ? '-active' : '')}>
                {question.question}
              </div>
              <div className={'question-arrow' + (question.isActive ? '-active' : '')}/>
            </div>
            <div className={'question-answer' + (question.isActive ? '-active' : '')}>
              {question.answer}
            </div>
          </div>
        })
      }
    </div>

  </div>;
}

import React from 'react';
import {getClassName} from "../../utils/StyleUtils";

interface Props {
    onBack?: (() => void) | undefined;
    isShowBack?: boolean;
    isShowLabel?: boolean;
}

export const HeaderOnboarding = ({onBack, isShowBack, isShowLabel}: Props) => {
    return <div className={getClassName('header-onboarding')}>
        {isShowBack ? <div className={'back'} onClick={onBack}>Back</div> : null}
        <div className={getClassName('fltr-onboarding-logo-container')}>
            <img className={getClassName('fltr-onboarding-logo')}
                 src={require('../../resources/imgs/logo.png')}
            />
            {isShowLabel ? <div className={getClassName('header-label')}>Presets for Lightroom</div> : null}
        </div>
    </div>
}

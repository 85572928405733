import React from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {configs} from "../../resources/configs";
import {MainResources} from "../../models/Resources";

export const TryApp = () => {

  return <div className={getClassName('try-screen-container')} id={'download'}>
      <img className={getClassName('try-app-img')} src={MainResources.tryApp.src}/>

      <div style={{position: 'absolute', top: '128px', display: 'flex', gap: '16px'}}>
      <div id={'download_AppStore'} style={{width: '142px', height: '56px'}}
           onClick={() => {
             window.open(configs.fltrIosUrl);
           }}
      />
      <div id={'download_GooglePlay'} style={{width: '142px', height: '56px'}}
           onClick={() => {
             window.open(configs.fltrAndroidUrl);
           }}
      />
    </div>
  </div>;
}

import React from 'react';

interface Props {
    percent: number;
    style: object
}

export const ProgressBar = ({percent, style}: Props) => {
    return <div className={'progress-bar-container'} style={style}>
        <div className={'progress-bar-back'}></div>
        <div className={'progress-bar-front'} style={{width: `${percent}%`}}></div>
    </div>
}

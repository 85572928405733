import {isMobile} from 'react-device-detect';

export const getClassName = (className: string) => {
  if (isMobile) {
    return className + '-mob';
  }

  return className;
};

export const spinnerColor = '#00DADA';

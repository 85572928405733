export enum AdSource {
    FACEBOOK = 'FACEBOOK',
    ORGANIC = 'ORGANIC',
    GOOGLE = 'GOOGLE'
}

export interface ForceLogoutParams {
    version: number,
}

export enum PageName {
    QUIZ_MAIN = 'web_quiz_v1_main',
    QUIZ_START = 'web_quiz_v1_start',
    QUIZ_FINISH = 'web_quiz_v1_quiz_finish',
    PAYWALL_DESCRIPTION = 'web_paywall_description',
    PAYWALL_PRODUCTS = 'web_paywall_products',
    PAYMENT = 'web_payment',
    PAYMENT_SUCCESS = 'web_payment_success',
    PAYMENT_FAILED = 'web_payment_failed'
}

import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PaywallProducts} from "./PaywallProducts";
import {useHistory} from "react-router-dom";
import {fltrProducts} from "../../models/Product";
import {PageName} from "../../models/Common";
import {GTMEvents} from '../../models/GTM';



export const PaywallBody = () => {
  const [selectedProductId, selectProductId] = useState<string>(fltrProducts[0].id);


  useEffect(() => {
      // @ts-ignore
    window.dataLayer.push({ 'event': GTMEvents.LEAD});
    // @ts-ignore
    window.dataLayer.push({ 'event': PageName.PAYWALL_PRODUCTS });
  }, [])

  let history = useHistory();

  return <div className={getClassName('paywall-body')}>
    <PaywallProducts
        selectedProductId={selectedProductId}
        selectProductId={selectProductId}
    />
    <button id={'Checkout'} className={getClassName('general-btn')} style={{marginBottom: 'auto'}}
      onClick={() => {
          const product = fltrProducts.find(product => product.id === selectedProductId);
          if(product) {
            // @ts-ignore
            window.dataLayer.push({ 'event': GTMEvents.CHECKOUT,
              'ecommerce': {
                'checkout': {
                  'products': [{
                    'name': product.description,
                    'id': product.id,
                    'price': product.amount / 100,
                    'quantity': 1
                  }]
                }
              }});
          }
          history.push(`/payment/${selectedProductId}`);
      }}
    >
      Continue
      <div className={'arrow-btn'}/>
    </button>
  </div>;
}


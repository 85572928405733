import React, {useEffect, useRef, useState} from 'react';
import {AllResources, MainResources, Resource, ResourceType} from "../../models/Resources";
import {Header} from "../general/Header";
import MDSpinner from "react-md-spinner";
import {spinnerColor} from "../../utils/StyleUtils";
import {preloadResource} from "../../utils/URLUtils";
import {MainScreen} from "./MainScreen";
import Features from "../features";
import {Reviews} from "./Reviews";
import {TryApp} from "./TryApp";
import {Questions} from "./Questions";
import {Footer} from "./Footer";

const Main = ({}) => {
    const [isLoadingResources, setIsLoadingResources] = useState(true)

    const mainRef = useRef(null);

    useEffect(() => {
        const loadResources = (resource: Resource) => {
            return new Promise((resolve, reject) => {
                if(resource.type === ResourceType.VIDEO) {
                    resolve(null);
                } else {
                    preloadResource(resource.src).then((src) => {
                        resource.blobSrc = src;
                        resolve(src);
                    })
                }
            })
        }

        Promise.all(Object.values(AllResources).map(resource => loadResources(resource)))
            .then(() => setIsLoadingResources(false))
            .catch(err => console.log("Failed to load images", err))
    }, [])
  return (
      <div ref={mainRef}>
          <Header/>
          {
              isLoadingResources ?
                  <div className='spinner-container' style={{height: '100VH'}}>
                    <MDSpinner size={100} singleColor={spinnerColor}/>
                 </div> : [
                     <MainScreen/>,
                      <Features
                          mainRef={mainRef}
                      />,
                      <div className="video-section">
                          <video src={MainResources.len2.src} autoPlay={true} muted playsInline loop onError={err => {
                              console.error('Video load error. ' + err);
                          }}>
                          </video>
                      </div>,
                      <Reviews/>,
                      <TryApp/>,
                      <Questions/>,
                      <Footer/>
                  ]
          }
      </div>
  );
};

export default Main;


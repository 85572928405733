export function getParameterFromURL(name: string): string | undefined {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return undefined;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export async function preloadResource(src: string) {
    const res = await fetch(src);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
}

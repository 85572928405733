import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {PaywallResources} from "../../models/Resources";
import momentZ from 'moment-timezone';
import {useHistory} from "react-router-dom";

export const PaywallOffer = () => {
  const [offerTime, setOfferTime] = useState(momentZ().add(15, 'minutes'));

  const refOffer = useRef(null);
  const refBackground = useRef(null);
  const refTimeOffer = useRef(null);
  let history = useHistory();

  const formatDate = (value: number): string => {
    if(value < 0) {
      return '00';
    }
    return value < 10 ? 0 + String(value) : String(value);
  }

  useEffect(() => {
      const elementOffer: any = refOffer.current;
      const timeOffer: any  = refTimeOffer.current;

      const displayIntervalId = setInterval(() => {
        if(elementOffer) {
          elementOffer.className = 'paywall-offer-bar-active'
        }
        clearInterval(displayIntervalId);
      }, 1000)

      const offerIntervalId = setInterval(() => {
        const duration = momentZ.duration(offerTime.diff(momentZ()))
        timeOffer.innerText = formatDate(duration.get('hours')) + ' : ' + formatDate(duration.get('minutes')) + ' : ' + formatDate(duration.get('seconds'));
      }, 1000)
  }, []);

  return <div className={getClassName('paywall-offer-view-container')}>
      <div className={'paywall-offer-bar'}
           ref={refOffer}
      >
        <div className={getClassName('paywall-offer-text')}>
          🔥 75% OFF ends in
        </div>
        <div className={getClassName('paywall-offer-text')} style={{marginLeft: 'auto'}}
             ref={refTimeOffer}
        ></div>
      </div>
    <div className={'back'} style={{zIndex: 20, top: '50px', left: '16px'}} onClick={() => {
      history.push(`/`);
    }}/>

    <div className={getClassName('paywall-offer-background-container')}>
      <video src={PaywallResources.offerBackground.src} ref={refBackground} autoPlay muted playsInline loop className="video-paywall" onError={err => {
        console.error('Video load error. ' + err);
      }}>
      </video>
    </div>
  </div>;
}

import React from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {bindActionCreators} from "redux";
import {onAllowCookies} from "../../actions/general";
import {connect} from "react-redux";

interface StateProps {
    isAllowed: boolean,
}

interface DispatchProps {
    onAllow: (isAllow: boolean) => void
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps

const Cookies = ({onAllow, isAllowed}: Props) => {
    if(isAllowed) {
        return null;
    }

    return <div className={getClassName('cookies-container')} >
        <span>
            This website uses cookies to ensure you get the best experience on our website.
                  <a href="https://onelightapps.io/w-privacy-policy" target="_blank">
                    <span>Learn more</span>
                  </a>
        </span>
        <div className={'allow-btn'} onClick={() => {onAllow(true)}}>
            OK
        </div>
    </div>
}


const mapStateToProps = (state: any) => ({
    isAllowed: state.appState.isAllowedCookies,
});

const mapDispatchToProps = (dispatch: any) => ({
    onAllow: bindActionCreators(onAllowCookies, dispatch),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Cookies);

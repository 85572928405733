import React from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {Product} from "../../models/Product";

interface StateProps {
  payPalScriptUrl?: string
}

interface DispatchProps {
}

interface OwnProps {product: Product}

type Props = StateProps & DispatchProps & OwnProps

export const OrderSummary = ({product}: Props) => {

  return <div className={getClassName('payment-order-summary-container')}>
    <div className={getClassName('payment-order-summary-title')}>
      Order Summary
    </div >
    <div className={getClassName('payment-order-summary-description-container')}>
      <div className={getClassName('payment-order-summary-description')} style={{fontWeight: 400}}>
        <div>{product.summaryDescription ? product.summaryDescription : product.description}</div>
        <div>{product.summaryPrice ? product.summaryPrice : product.price}</div>
      </div>
      <div className={getClassName('payment-order-summary-description')}>
        {
          product.save ? <div className={'fltr-general-text-gradient'}>
            Discount {product.save} OFF
          </div> : null
        }
        <div className={'fltr-general-text-gradient'}>
          {product.discount ? product.discount : ''}
        </div>
      </div>
    </div>
    <div className={getClassName('payment-order-summary-line')}/>
    <div className={getClassName('payment-order-summary-total')}>
      <div>Total</div>
      <div>{product.total}</div>
    </div>

  </div>;
};

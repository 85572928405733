import { configs } from "../resources/configs";

export interface Product {
    id: string,
    description: string,
    price: string,
    save?: string,
    discount?: string,
    summaryDescription?: string
    summaryPrice?: string
    amount: number
    currency: string
    total: string
    isLifetime: boolean
}

export const fltrProducts: Product[] = configs.isDev ? [
    {
        id: 'e5d626e8-4557-46f3-84c9-b7f7901512b2',
        description: '12 Months $29.99',
        price: '$2.49/month',
        save: '75%',
        discount: '-$89.89',
        summaryDescription: '12 Months FLTR PRO',
        summaryPrice: '$119.88',
        amount: 2999,
        currency: 'USD',
        total: '$29.99',
        isLifetime: false
    },
    {
        id: '44d662c9-0327-4ec0-bc12-aea06c1f8149',
        description: '1 Month',
        price: '$9.99',
        summaryDescription: '1 Month FLTR PRO',
        amount: 999,
        currency: 'USD',
        total: '$9.99',
        isLifetime: false
    },
    {
        id: 'c52bdff6-fcbb-4abc-a2f9-db6ff4521117',
        description: 'One-Time Purchase',
        price: '$69.99',
        amount: 6999,
        currency: 'USD',
        total: '$69.99',
        isLifetime: true
    }
] : [
    {
        id: '92872ade-513a-4714-8b51-1462ceac0359',
        description: '12 Months $29.99',
        price: '$2.49/month',
        save: '75%',
        discount: '-$89.89',
        summaryDescription: '12 Months FLTR PRO',
        summaryPrice: '$119.88',
        amount: 2999,
        currency: 'USD',
        total: '$29.99',
        isLifetime: false
    },
    {
        id: 'fc16e856-2596-47cc-bbbb-537648634497',
        description: '1 Month',
        price: '$9.99',
        summaryDescription: '1 Month FLTR PRO',
        amount: 999,
        currency: 'USD',
        total: '$9.99',
        isLifetime: false
    },
    {
        id: 'c52bdff6-fcbb-4abc-a2f9-db6ff4521117',
        description: 'One-Time Purchase',
        price: '$69.99',
        amount: 6999,
        currency: 'USD',
        total: '$69.99',
        isLifetime: true
    }
]

import React, { Component } from 'react'
import { Authenticator } from './Authenticator'
import {getLogoutId, isAuth, registerLogout} from '../../utils/AuthUtill'

type Props = {};
type State = { signedIn: any, initLogoutId: string };
export const withAuthenticator = (Comp: any) => {
    return class extends Component<Props, State> {
        constructor (props: any) {
            super(props)
            this.state = {
                signedIn: isAuth(),
                initLogoutId: getLogoutId()
            }
            registerLogout(this.handleAuthStateChange)
        }

        handleAuthStateChange = (state: any) => {
            this.setState({ signedIn: state })
        }

        render () {
            let { signedIn, initLogoutId } = this.state;
            if (signedIn || initLogoutId !== getLogoutId()) {
                return (
                    <Comp
                        {...this.props}
                    />
                )
            }

            return <Authenticator
                onStateChange={this.handleAuthStateChange}
            />
        }
    }
}

import {saveItem, getItem} from './LocalStoargeUtill';
import {Auth} from "../models/Auth";
import {configs} from "../resources/configs";
import {ForceLogoutParams} from "../models/Common";
import {User} from "../models/User";
import uuid from "uuid";

export const saveAuth = (auth: Auth) => {
  if (auth.user) {
    saveItem(auth.user, 'user');
  }

  if (auth.cookies) {
    saveItem(auth.cookies, 'cookies');
    auth.cookies.forEach((cookie) => {
      document.cookie = cookie;
    });
  }
};

export const isAuth = () => {
  let forceLogoutParams: ForceLogoutParams = getItem('force_logout_params', null);
  if(!forceLogoutParams || forceLogoutParams.version < configs.forceLogoutVersion) {
    forceLogoutParams = {version: configs.forceLogoutVersion};
    saveItem(forceLogoutParams, 'force_logout_params');
    saveItem(null, 'user');
    return false;
  }

  const user = getItem('user', null);

  return user !== null;
};

export const registerCookiesFromLocalStorage = () => {
  const cookies = getItem('cookies', []);

  cookies.forEach((cookie: string) => {
    document.cookie = cookie;
  });
};

export const getUser = (): User => {
  return getItem('user', null);
};

let handlerAuthState: any = null;

export const registerLogout = (logoutFunc: any) => {
  handlerAuthState = logoutFunc;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const logoutApp = (logoutId: string) => {
  localStorage.clear();
  saveItem(logoutId, 'logoutId');
  sessionStorage.clear();
  deleteAllCookies();
  handlerAuthState(false);
};

export const getLogoutId = (): string => {
  const logoutId = getItem('logoutId', uuid.v4());
  saveItem(logoutId, 'logoutId');

  return logoutId;
};

export const setLogoutId = (logoutId: string) => {
  saveItem(logoutId, 'logoutId');
};

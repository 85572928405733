import React, { useEffect, useRef, useState } from 'react'
import Payment, { SdkMessage, MessageType, ClientSdkInstance } from "@solidgate/react-sdk"
import FormType from '@solidgate/client-sdk-loader/dist/enums/FormType';
import FormButtonType from '@solidgate/client-sdk-loader/dist/enums/FormButtonType';
import { SolidgateErrorType, SolidgateMerchantData } from "../../models/Solidgate";
import { getClassName, spinnerColor } from "../../utils/StyleUtils";
import { IFRAME_CONTAINER_ID } from "@solidgate/react-sdk/dist/esm/constants";
import MDSpinner from "react-md-spinner";

/**
 * Configuration, as it described here
 * https://dev.solidgate.com/developers/documentation/solid-payment-form
 */

interface Props {
  merchantData: SolidgateMerchantData,
  appleContainerRef: any,
  googleContainerRef: any
  onSuccess: () => void,
  onFail: () => void,
}

const formParams = {
  buttonType: FormButtonType.Default,
  submitButtonText: 'Continue',
  isCardHolderVisible: false,
  hideCvvNumbers: true,
  headerText: 'Enter your debit or credit card details (from merchant)',
  titleText: 'Card info (from merchant)',
  formTypeClass: FormType.Default,
  autoFocus: false,
}

const customFormStyles = {
  input_group: {
    'font-weight': '400',
    'font-size': '16px',
    'line-height': '24px',
    'color': '#979797',
    '.error-text': {
      'color': '#FA655C',
      'line-height': '14px',
      '.triangle': {
        'display': 'none'
      }
    },
    '.brand-icon': {
      //'background-image': `url(${require('../../resources/imgs/credit-card.png')})`,
      'position': 'absolute',
      'height': '24px',
      'width': '24px',
      'top': '14px',
      'background-size': 'cover'
    },
    '.tooltip-icon': {
      'top': '12px',
      ':before': {
        'content': '"?"',
        background: '#303333',
        'color': '#000000',
        opacity: 1,
      },
      ':hover': {
        ':before': {
          'content': '"?"',
          background: '#ffffff',
          'color': '#000000',
          opacity: 1,
        }
      }
    },
    ".label": {
      "color": "#979797",
      "font-size": "14px",
      "line-height": "17px",
      "font-weight": '400',
      "display": 'none'
    },
    '.error .label': {
      'color': '#979797',
      "font-size": "14px",
      "line-height": "14px",
      "font-weight": '400'
    },
    '.valid .label': {
      'color': '#979797',
      "font-size": "14px",
      "line-height": "14px",
      "font-weight": '400'
    },
    '.not-empty .label': {
      'color': '#979797',
      "font-size": "14px",
      "line-height": "17px",
      "font-weight": '400'
    },
    "input":
    {
      "font-family": "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\"",
      'border': '1px solid #979797',
      'border-radius': '50px',
      'background-color': 'black',
      'padding-left': '24px!important',
      'height': '48px',
      'color': '#FFFFFF',
      "margin-bottom": "12px",
      ":focus":
      {
        "border-color": "#FFFFFF"
      },
      '::placeholder': {
        'font-weight': '400',
        'font-size': '16px',
        'line-height': '24px',
        'color': '#979797',
      }
    },
    '.input-block.error input': {
      'border': '1px solid #FA655C',
    },
    '.input-block.valid input': {
      'border': '1px solid #FFFFFF',
    },
  },
  submit_button: {
    'background-image': 'linear-gradient(85.81deg, #21FFB0 33.39%, #21E9EB 64.73%, #21E2FF 75.17%)',
    'font-size': '18px',
    'font-weight': '700',
    'line-height': '21px',
    'height': '56px',
    'border-radius': '40px',
    'color': '#000000',
    'margin-top': '12px',
    ':disabled': {
      'background-image': 'linear-gradient(172deg, #21FFB0 0%, #21E9EB 75.03%, #21E2FF 100%)'
    },
    ':hover': {
      'opacity': '0.8'
    },
    '.title': {
      'text-transform': 'capitalize',
    },
    '.title-icon': {
      'background': 'none!important',
      'width': '0px'
    }
  },
  form_body: {
    "font-family": "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\"",
    'background-color': 'transparent',
    'width': '100%',
    'height': '100%',
    '.default.form_body': {
      'padding-top': 'none',
    },
    '.form-container': {
      'display': 'flex',
      'flex-direction': 'column',
      'width': '100%',
      'padding-top': '20px',
      'height': '500px',
      '.two-columns': {
        'margin': '0px'
      },
    }
  },
  card_view: {
    'background-color': '#000000',
    'background-image': 'linear-gradient(#000000, #000000)'
  }
}

const SolidgateForm = ({ merchantData, appleContainerRef, googleContainerRef, onSuccess, onFail }: Props) => {
  const [isLoading, setIsLoading] = useState(true)

  const handleOnError = (e: SdkMessage[MessageType.Error]) => {
    console.log('handleOnError______________');
    console.log(e);

    // @ts-ignore
    window.dataLayer.push({ 'event': SolidgateErrorType.Error, value: JSON.stringify(e.value) });
    onFail()
  }

  const handleOnFail = (e: SdkMessage[MessageType.Fail]) => {
    console.log('handleOnFail______________');
    console.log(e);
    // @ts-ignore
    window.dataLayer.push({ 'event': SolidgateErrorType.Fail, value: JSON.stringify(e) });
    onFail()
  }

  const handleOnMounted = (e: SdkMessage[MessageType.Mounted]) => {
    console.log(e);
    const iframContainer = document.getElementById(IFRAME_CONTAINER_ID);

    if (iframContainer) {
      setIsLoading(false);
      iframContainer.style.width = '100%';
    }
  }

  const handleOrderStatus = (e: SdkMessage[MessageType.OrderStatus]) => { console.log(e) }

  const handleOnResize = (e: SdkMessage[MessageType.Resize]) => { console.log(e) }

  const handleOnSuccess = (e: SdkMessage[MessageType.Success]) => {
    console.log('onSuccess______________');
    console.log(e);
    onSuccess()
  }

  const handleOnSubmit = (e: SdkMessage[MessageType.Submit]) => { console.log(e) }

  const handleOnInteraction = (e: SdkMessage[MessageType.Interaction]) => { console.log(e) }

  const handleOnVerify = (e: SdkMessage[MessageType.Verify]) => { console.log(e) }

  const handleOnRedirectMessage = (e: SdkMessage[MessageType.Redirect]) => { console.log(e) }

  const handleOnCustomStylesAppended = (e: SdkMessage[MessageType.CustomStylesAppended]) => { console.log(e) }

  const handleCard = (e: SdkMessage[MessageType.Card]) => { console.log(e) }

  const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
    // eslint-disable-next-line no-console
    console.log('form', form)
  }

  return <div className={getClassName('payment-solidgate-container')}>
    <div style={{ width: '100%' }}>
      <Payment
        applePayContainerRef={appleContainerRef}
        applePayButtonParams={{
          type: 'pay',
          color: 'white',
          enabled: true,
        }}
        googlePayContainerRef={googleContainerRef}
        googlePayButtonParams={{
          color: 'white',
          type: 'plain'
        }}
        merchantData={merchantData}
        //@ts-ignore
        formParams={formParams}
        styles={customFormStyles}
        onError={handleOnError}
        onFail={handleOnFail}
        onCard={handleCard}
        onMounted={handleOnMounted}
        onOrderStatus={handleOrderStatus}
        onResize={handleOnResize}
        onSuccess={handleOnSuccess}
        onSubmit={handleOnSubmit}
        onInteraction={handleOnInteraction}
        onVerify={handleOnVerify}
        onFormRedirect={handleOnRedirectMessage}
        onCustomStylesAppended={handleOnCustomStylesAppended}
        onReadyPaymentInstance={handleOnReadyPaymentInstance}
        width={'100%'}
      />
    </div>
    {isLoading ? <div className='spinner-container' style={{ height: '300px' }}>
      <MDSpinner size={50} singleColor={spinnerColor} />
    </div> : null
    }
  </div>
}

export default SolidgateForm;

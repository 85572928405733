import {createRequest} from "../utils/HttpRequestUtills";
import {SolidgateSignatureResponse} from "../models/Responses";
import {Product} from "../models/Product";

export enum GeneralActionType {
  SET_IS_LOADING_MERCHANT_DATA = 'SET_IS_LOADING_MERCHANT_DATA',
  SAVE_MERCHANT_DATA = 'SAVE_MERCHANT_DATA',
  SAVE_PAYPAL_SCRIPT = 'SAVE_PAYPAL_SCRIPT',
  SELECT_PRODUCT = 'SELECT_PRODUCT',
  SET_IS_SHOW_PAYMENT_FORM = 'SET_IS_SHOW_PAYMENT_FORM',
  SET_IS_LOADING_PAYPALL_SCRIPT = 'SET_IS_LOADING_PAYPALL_SCRIPT',
  SET_IS_ALLOW_COOKIES = 'SET_IS_ALLOW_COOKIES'
}

export const selectProduct = (product: Product) => (dispatch: any) => {
  dispatch({type: GeneralActionType.SET_IS_LOADING_MERCHANT_DATA, isLoading: true});
  dispatch({type: GeneralActionType.SELECT_PRODUCT, product});

  const onload = (responseText: string) => {
    const response: SolidgateSignatureResponse = JSON.parse(responseText);
    dispatch({
      type: GeneralActionType.SAVE_MERCHANT_DATA,
      merchantData: response.merchantData,
      redirectAppUrl: response.redirectAppUrl,
      payPalScriptUrl: response.payPalScriptUrl,
      orderId: response.orderId
    });
  };
  const xhr = createRequest(dispatch, 'POST', `/web-payments/solidgate-signature/generate`, onload);

  xhr.send(JSON.stringify({productId: product.id}));
};

export const setIsShowPaymentForm = (isShow: boolean) => (dispatch: any) => {
  dispatch({type: GeneralActionType.SET_IS_SHOW_PAYMENT_FORM, isShow});
};

export const onAllowCookies = (isAllow: boolean) => (dispatch: any) => {
  dispatch({type: GeneralActionType.SET_IS_ALLOW_COOKIES, isAllow});
};

import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @ts-ignore
import {ReviewResources} from "../../models/Resources";
import {range} from "../../utils/ArrayUtils";

const style = {width: '311px', marginRight: '5px', marginLeft: '5px'}
export const Reviews = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        touchThreshold: 20,
        variableWidth: true,
        initialSlide: 0,
        centerMode: true,
        afterChange: (index: number) => {
                setActiveSlideIndex(index)
        },
    };
    return <div className={getClassName('review-container')} id={'reviews'}>
      <div className={getClassName('title')}>
        Join millions<br/> of happy users
      </div>
      <img width={'276px'} style={{paddingTop: '16px', paddingBottom: '24px'}} src={ReviewResources.reviewRating.blobSrc}/>
        <div style={{width: '100%', height: '256px'}}>
            <Slider {...settings}>
                <div>
                    <img style={style} src={ReviewResources.review1.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={ReviewResources.review2.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={ReviewResources.review3.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={ReviewResources.review4.blobSrc}/>
                </div>
                <div>
                    <img style={style} src={ReviewResources.review5.blobSrc}/>
                </div>
            </Slider>
        </div>
        <div className={'review-dots-container'}>
            {range(5).map((dotIndex) => {
                return <div className={'review-dot'} style={{opacity: (dotIndex === activeSlideIndex ? 1 : .2)}}/>
            })}
        </div>
  </div>;
}

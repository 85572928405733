import React, {useEffect, useRef} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {fltrProducts} from "../../models/Product";

interface Props {
    selectProductId: (productId: string) => void
    selectedProductId: string
}

export const PaywallProducts = ({selectedProductId, selectProductId}: Props) => {
    const refBody = useRef(null);

    useEffect(() => {
        if(refBody && refBody.current) {
            const x = 0.482908;
            const y = 26.344651;
            const h = window.innerHeight;
            const hy = h * y;
            const hx = h * x;
            const d = hx * hx - 4 * hy;
            let z1 = (2 * hy) / (hx - Math.sqrt(d));
            z1 = z1 > 400 ? 400 : z1;
            // @ts-ignore
            refBody.current.style.paddingTop = z1 + 'px';
        }
    }, [])

    return <div ref={refBody} className={getClassName('paywall-products-container')}>
    <div className={'paywall-products-list'}>
        {
            fltrProducts.map(product => {
                const isActive = product.id === selectedProductId;
                return <div className={getClassName(isActive ? 'paywall-product-active' : 'paywall-product')} onClick={() => {selectProductId(product.id)}}>
                    <div className={isActive ? 'product-circle-active' : 'product-circle'}></div>
                    <div className={'paywall-product-price-container'}>
                        <div className={'paywall-product-price-title'}>
                            {product.description}
                        </div>
                        <div className={'paywall-product-price-subtitle'}>
                            {product.price}
                        </div>
                    </div>
                    {product.save ? <div className={(isActive ? 'paywall-product-price-save-active' : 'paywall-product-price-save')}>SAVE {product.save}</div> : null}
                </div>
            })
        }
    </div>

      <div className={'paywall-guarantee-container'}>
          30-day money-back guarantee
      </div>
  </div>;
}

import React, {useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {configs} from "../../resources/configs";
import {MainResources} from "../../models/Resources";
import {useHistory} from "react-router-dom";

export const Header = () => {
    const [isActiveMainNavigation, setIsActiveMainNavigation] = useState(false)
    let history = useHistory();

    const renderBurger = () => {
        return <div className={'burger-button' + (isActiveMainNavigation ? ' open' : '')} onClick={() => {setIsActiveMainNavigation(!isActiveMainNavigation)}}>
            <div className={'icon'}></div>
        </div>
    }

    return <div className={getClassName('header')} style={{backgroundColor: isActiveMainNavigation ? 'black' : undefined}}>
        <div style={{display: 'flex', padding: '12px 16px 0px 16px'}}>
            <img className={getClassName('fltr-logo')}
                 src={require('../../resources/imgs/logo.png')}
            />
            <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '16px'}}>
                <img className={getClassName('try-now')}
                     onClick={() => {
                         history.push('/paywall')
                     }}
                     src={require('../../resources/imgs/try-now.png')}
                />
                {renderBurger()}
            </div>
        </div>
        <div className={isActiveMainNavigation ? "menu-opened" : "menu"}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '48px'}}>
                <div className="menu-item"
                     onClick={() => {
                         setIsActiveMainNavigation(false)
                     }}
                ><a href="#features">features</a></div>
                <div className="menu-item"
                     onClick={() => {
                         setIsActiveMainNavigation(false)
                     }}
                ><a href="#reviews">reviews</a></div>
                <div className="menu-item"
                     onClick={() => {
                         setIsActiveMainNavigation(false)
                     }}
                ><a href="#faq">faq</a></div>
                <div className="menu-item"
                     onClick={() => {
                         setIsActiveMainNavigation(false)
                     }}
                ><a href="#download">download</a></div>
            </div>
            <img style={{width: '195px', height: '24px', padding: '24px'}}
                 src={MainResources.instagramFollow.blobSrc}
                 onClick={() => {
                     window.open('https://www.instagram.com/fltr.pro/');
                 }}
            />
        </div>
    </div>
}

import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
// @ts-ignore
import Slider from 'react-input-slider';
import {useHistory} from "react-router-dom";
import {MainResources} from "../../models/Resources";
import {configs} from "../../resources/configs";

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

type Props = StateProps & DispatchProps & OwnProps;

export const MainScreen = () => {
  const [state, setState] = useState({ x: 30, y: 10 });
  const [isReload, setIsReload] = useState(true);

  const refContainer = useRef(null);
  const refVideo = useRef(null);

  useLayoutEffect(() => {
    const elementVideo: any = refVideo.current;
    if(elementVideo) {
      elementVideo.addEventListener('loadedmetadata',() => {
        console.log('ff')
        // @ts-ignore
        elementVideo.play();

        elementVideo.currentTime = 0.1;
      });
    }
    // @ts-ignore
    setIsReload(false)
  }, [])
  const renderBeforeAfter = () => {
    return <div className={'main-before-after'}>
      {isReload ? '---' : null}
      <img className={getClassName('iphone-img')} src={MainResources.iPhone.blobSrc}/>
      <div ref={refContainer} className={getClassName('main-before-after-container')}>
        <div className={getClassName('main-before-after-subcontainer')}>
            <img  className={'image-before slider-image'} src={MainResources.mainAfter.blobSrc}/>
            <img  className={'slider-image'} src={MainResources.mainBefore.blobSrc}/>
        </div>
        <Slider
            axis="x"
            x={state.x}
            onChange={({ x }) => {
              setState(state => ({ ...state, x }))
              // @ts-ignore
              refContainer.current.style.setProperty('--position', `${x}%`);
            }}
            styles={{
              track: {
                opacity: 0,
                width: '100%',
                height: '100%',
                zIndex: 10,
                position: "absolute"
              },
              active: {
                opacity: 0
              },
              thumb: {
                opacity: 0
              },
              disabled: {
                opacity: 0
              }
            }}
        />
      <div className="slider-line" aria-hidden="true"></div>
      <div className="slider-button" aria-hidden="true"></div>
    </div>
    </div>
  }

  return <div className={getClassName('main-screen-container')} key={'main-screen-container'}>
    <div className={getClassName('main-background-container')}/>
    <img height={'17px'}  style={{paddingTop: '60px'}} src={MainResources.mainSocialProof.blobSrc}/>
    <div className={getClassName('title')} style={{textAlign: 'center', paddingTop: '12px'}}>
      1500+ Presets<br/>
      for Lightroom Mobile
    </div>
    <div className={getClassName('main-subtitle')} style={{marginTop: '6px', textAlign: 'center'}}>
      Photo & Video Filters
    </div>
    {renderBeforeAfter()}
    <div className={'main-line'}/>
{/*    <button className={'empty-gradient-btn'} style={{marginTop: '20px', marginBottom: '32px'}}
            onClick={() => {
              window.open(configs.fltrWebPaymentsUrl,"_self");
            }}
    >
      Start Now
      <div className={'arrow-btn'}/>
    </button>*/}

    <div key={'len1'} className="video-section" style={{paddingTop: '20px'}}>
      <video  autoPlay muted playsInline loop
              src={MainResources.len1.src}
              ref={refVideo}
              preload="metadata"
              onError={err => {
        console.error('Video load error. ' + err);
      }} >
      </video>
      <div>
        Make trendy content with unique filters and get professional look in just one tap.
      </div>
    </div>
  </div>;
}

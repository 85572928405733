import React, {useEffect, useState} from 'react';
import {QuizFlowView} from "./QuizFlowView";
import {QuizResources, Resource, ResourceType} from "../../models/Resources";
import {Header} from "../general/Header";
import MDSpinner from "react-md-spinner";
import {spinnerColor} from "../../utils/StyleUtils";
import {preloadResource} from "../../utils/URLUtils";
import {HeaderOnboarding} from "../general/HeaderOnboarding";

const Quiz = ({}) => {
    const [isShowBack, setIsShowBack] = useState(false)
    const [numberActiveFlow, setNumberActiveFlow] = useState(0);
    const [isActiveFlow, setIsActiveFlow] = useState(false);
    const [isActiveMain, setIsActiveMain] = useState(true);

    const [isLoadingResources, setIsLoadingResources] = useState(true)

    useEffect(() => {
        const loadResources = (resource: Resource) => {
            return new Promise((resolve, reject) => {
                preloadResource(resource.src).then((src) => {
                    resource.blobSrc = src;
                    resolve(src);
                })
            })
        }

        Promise.all(Object.values(QuizResources).map(resource => loadResources(resource)))
            .then(() => setIsLoadingResources(false))
            .catch(err => console.log("Failed to load images", err))
    }, [])

    const onBack = () => {
        if(numberActiveFlow === 0) {
            setIsActiveFlow(false);
            setIsShowBack(false);
        } else {
            setNumberActiveFlow(numberActiveFlow - 1);
        }
    }
  return (
      <div className={'quiz-page-container'}>
          <HeaderOnboarding
              isShowBack={isShowBack}
              isShowLabel={!isActiveFlow && !isActiveMain}
              onBack={onBack}
          />
          {
              isLoadingResources ?
                  <div className='spinner-container' style={{height: '100VH'}}>
                    <MDSpinner size={100} singleColor={spinnerColor}/>
                 </div> :
                  <QuizFlowView
                      setIsShowBack={setIsShowBack}
                      numberActiveFlow={numberActiveFlow}
                      setNumberActiveFlow={setNumberActiveFlow}
                      isActiveFlow={isActiveFlow}
                      setIsActiveFlow={setIsActiveFlow}
                      setIsActiveMain={setIsActiveMain}
                      isActiveMain={isActiveMain}
                  />
          }
      </div>
  );
};

export default Quiz;


import {AdSource, PageName} from "../models/Common";
import {FacebookAdProps} from "../models/Facebook";
import {getParameterFromURL} from "./URLUtils";

enum FacebookEventType {
    PageView = 'PageView',
    InitiateCheckout = 'InitiateCheckout',
    Lead = 'Lead',
    Purchase = 'Purchase',
    ViewContent = 'ViewContent'
}
export const FacebookUtils = {
    trackInitiateCheckout: (currency: string, amount: number) => {
        // @ts-ignore
        window.fbq('track', FacebookEventType.InitiateCheckout, {currency, value: amount})
    },
    trackViewContent: (page: PageName | string) => {
        // @ts-ignore
        window.fbq('track', FacebookEventType.ViewContent, {content_name: page})
    },
    trackLead: () => {
        // @ts-ignore
        window.fbq('track', FacebookEventType.Lead)
    },
    trackPurchase: (currency: string, amount: number, contentName: string) => {
        // @ts-ignore
        window.fbq('track', FacebookEventType.Purchase, {currency, value: amount, content_name: contentName})
    },

    getFacebookAdProps: (): FacebookAdProps => {
        if (document.referrer && document.referrer != "" && document.referrer.toLowerCase().includes('facebook')) {
            const utmContent = getParameterFromURL('utm_content');
            let adSet = utmContent && utmContent.includes('-') ? utmContent.split('-')[0] : undefined;
            let adName = utmContent && utmContent.includes('-') ? utmContent.split('-')[1] : undefined;

            return {
                adSource: AdSource.FACEBOOK,
                adCampaign: getParameterFromURL('utm_campaign'),
                adSet,
                adName,
                fbclid: getParameterFromURL('fbclid')
            }
        } else {
            return {};
        }
    }
}

import React, {useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {range} from "../../utils/ArrayUtils";
import {countFeatures} from "./index";
import {FeaturesResources} from "../../models/Resources";

interface StateProps {
    activeFeaturesIndex: number,
    setActiveFeaturesIndex: (index: number) => void
}

type Props = StateProps
export const FeaturesHeader = ({activeFeaturesIndex, setActiveFeaturesIndex}: Props) => {

    return <div className={getClassName('features-header')}>
        {
            range(countFeatures, 1).map((featuresIndex, index) => {
                const isActive = activeFeaturesIndex === featuresIndex -1;
                const activeIconName = `activeIcon${index + 1}`
                const iconName = `icon${index + 1}`
                return <div className={getClassName('feature-icon-container')}>
                    <img className={getClassName('feature-icon')}
                         style={{opacity: isActive ? 0 : 1}}
                         src={FeaturesResources[iconName].blobSrc}
                         key={iconName}
                         onClick={(e) => {
                             if(!isActive) {
                                 setActiveFeaturesIndex(index);
                             }
                         }}
                    />
                    <img className={getClassName('feature-icon')}
                         style={{opacity: isActive ? 1 : 0}}
                         src={FeaturesResources[activeIconName].blobSrc}
                         key={activeIconName}
                         onClick={(e) => {
                             if(!isActive) {
                                 setActiveFeaturesIndex(index);
                             }
                         }}
                    />
                </div>
            })
        }
    </div>
}

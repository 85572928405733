import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {MainResources} from "../../models/Resources";
import {configs} from "../../resources/configs";

export const Footer = () => {

  return <div className={getClassName('footer-container')}>
    <img style={{width: '111px', height: '40px'}}
         src={require('../../resources/imgs/logo.png')}
    />
    <div style={{display: 'flex', gap: '16px'}}>
      <img id={'footer_AppStore'} style={{width: '143px', height: '44px', paddingTop: '24px'}}
           src={MainResources.footerStoreApple.blobSrc}
           onClick={() => {
             window.open(configs.fltrIosUrl);
           }}
      />
      <img id={'footer_GooglePlay'} style={{width: '143px', height: '44px', paddingTop: '24px'}}
           src={MainResources.footerStoreGoogle.blobSrc}
           onClick={() => {
             window.open(configs.fltrAndroidUrl);
           }}
      />
    </div>

    <img id={'footer_Instagram'} style={{width: '195px', height: '24px', padding: '24px'}}
         src={MainResources.instagramFollow.blobSrc}
         onClick={() => {
           window.open('https://www.instagram.com/fltr.pro/');
         }}
    />
    <div className={'terms-policy-footer'}>
      <a href="https://onelightapps.io/w-terms-of-use" target='_blank'>
        Terms of Use
      </a>
      <div className={'point'}/>
      <a href="https://onelightapps.io/w-privacy-policy" target='_blank'>
        Privacy Policy
      </a>
      <div className={'point'}/>
      <a href={`mailto:support@fltr.pro?subject=FLTR%20Web`}>
        Support
      </a>
    </div>
    <div className={'footer-copyright'}>
      Copyright © 2024 FLTR. All rights reserved.
    </div>
  </div>;
}

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {FeaturesResources} from "../../models/Resources";
import {getClassName} from "../../utils/StyleUtils";
import {FeaturesHeader} from "./FeaturesHeader";
import {nearestValue, range} from "../../utils/ArrayUtils";
import {configs} from "../../resources/configs";
import {useHistory} from "react-router-dom";


export const countFeatures = 5;

interface StateProps {
    mainRef: any,
}

type Props = StateProps

const Features = ({mainRef}: Props) => {
    const [activeFeaturesIndex, setActiveFeaturesIndex] = useState(0)
    const [st, setScrollTrigger] = useState<gsap.core.Tween>()
    const featuresContainer = useRef(null);
    let history = useHistory();

    const snap = [0, .25, .5, .75, 1];
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            let features = gsap.utils.toArray(".feature-content");
            const st = gsap.to(features, {
                xPercent: -100 * (features.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: featuresContainer.current,
                    // @ts-ignore
                    pin: true,
                    scrub: 0.1,
                    onUpdate: (self: any) => {
                        const indexActive = snap.indexOf(nearestValue(snap, self.progress));
                        setActiveFeaturesIndex(indexActive);
                    },
                    snap: {
                        snapTo: snap,
                        inertia: false,
                    },
                    // @ts-ignore
                    markers: false,
                    end: "+=3000",
                }
            });
            setScrollTrigger(st);
        }, mainRef);

        return () => ctx.revert();
    }, [false]);

  return (
      <div ref={featuresContainer} className={'features-container'} id={'features'}>
          <FeaturesHeader
              activeFeaturesIndex={activeFeaturesIndex}
              setActiveFeaturesIndex={(index) => {
                  //setActiveFeaturesIndex(index);
                  // @ts-ignore
                  gsap.to(window, {
                      scrollTo: {
                          y: st!.scrollTrigger!.start + ((st!.scrollTrigger!.end - st!.scrollTrigger!.start) / 4) * (index),
                          autoKill: false
                      },
                      duration: 0.5,
                  });
              }}
          />
              {
                  range(countFeatures, 1).map((featuresIndex, index) => {
                      const backgroundName = `background${featuresIndex}`;
                      return <div  className={`feature-content`} key={`feature-${index}`} id={`feature-${index}`}>
                          <div style={{height: '92px'}}></div>
                          <img className={getClassName(`feature-background`)}
                                  src={FeaturesResources[backgroundName].blobSrc}
                                  key={backgroundName}
                            />
                      </div>
                  })
              }
          {activeFeaturesIndex !== 0 ?
              <div className={'feature-start-to-free-btn-container'}>
                  <div className={'feature-start-to-free-btn'}
                       onClick={() => {
                           history.push('/paywall')
                       }}
                  >
                      Start Now
                  </div>
              </div>  : null
          }
      </div>
  );
};

export default Features;


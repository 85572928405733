import {ResourceName} from "./Resources";

export enum QuizType {
    SINGLE = 'SINGLE',
    MULTI = 'MULTI',
}

export enum QuizFlowDisplayType {
    COLUMN = 'COLUMN',
    DOUBLE_COLUMN_LAST_SINGLE = 'DOUBLE_COLUMN_LAST_SINGLE'
}

export interface Answer {
    text?: string;
    isSelected?: boolean;
    isOnlyItSelected?: boolean;
}

export interface QuizFlow {
    id: string,
    answers: Answer[],
    title: string,
    quizFlowType: QuizFlowDisplayType,
    type: QuizType;
    resourceName: ResourceName;
}

export const fltrQuizFlow: QuizFlow[] = [
    {   id: 'web_quiz_v1_q1_skill_level',
        title: 'What\'s your editing skill level?',
        quizFlowType: QuizFlowDisplayType.COLUMN,
        type: QuizType.SINGLE,
        resourceName: 'quizCover1',
        answers: [
            {text: 'I\'m a beginner', isSelected: false},
            {text: 'I know some basics', isSelected: false},
            {text: 'I am a skilled user', isSelected: false},
            {text: 'I’m a PRO', isSelected: false}
        ]
    },
    {   id: 'web_quiz_v1_q2_editing_goal',
        title: 'What is your editing goal?',
        quizFlowType: QuizFlowDisplayType.COLUMN,
        type: QuizType.SINGLE,
        resourceName: 'quizCover2',
        answers: [
            {text: 'I’m an influencer', isSelected: false},
            {text: 'Promoting my Business', isSelected: false},
            {text: 'My personal social media', isSelected: false},
            {text: 'Just for fun', isSelected: false}
        ]
    },
    {   id: 'web_quiz_v1_q3_content_types',
        title: 'What types of content do you typically edit?',
        quizFlowType: QuizFlowDisplayType.COLUMN,
        type: QuizType.MULTI,
        resourceName: 'quizCover3',
        answers: [
            {text: 'Lifestyle', isSelected: false},
            {text: 'Business & Marketing', isSelected: false},
            {text: 'Portraits', isSelected: false},
            {text: 'Travel', isSelected: false}
        ]
    },
    {   id: 'web_quiz_v1_q4_editing_frequency',
        title: 'How often do you edit photos and/or videos?',
        quizFlowType: QuizFlowDisplayType.COLUMN,
        type: QuizType.SINGLE,
        resourceName: 'quizCover4',
        answers: [
            {text: 'Daily', isSelected: false},
            {text: 'Several times a week', isSelected: false},
            {text: 'Once a week', isSelected: false},
            {text: 'A few times a month', isSelected: false},
            {text: 'Rarely', isSelected: false},
        ]
    },
    {   id: 'web_quiz_v1_q5_editing_style',
        title: 'What is your preferred editing style?',
        quizFlowType: QuizFlowDisplayType.COLUMN,
        type: QuizType.MULTI,
        resourceName: 'quizCover5',
        answers: [
            {text: 'Natural/realistic', isSelected: false},
            {text: 'Vintage/film-like', isSelected: false},
            {text: 'Light and airy', isSelected: false},
            {text: 'Dark and moody', isSelected: false}
        ]
    },
    {   id: 'web_quiz_v1_q6_editing_workflow',
        title: 'What is your preferred editing workflow?',
        quizFlowType: QuizFlowDisplayType.COLUMN,
        type: QuizType.SINGLE,
        resourceName: 'quizCover6',
        answers: [
            {text: 'Editing on mobile devices', isSelected: false},
            {text: 'Editing on a desktop or laptop', isSelected: false},
            {text: 'A combination of both', isSelected: false}
        ]
    },
    {   id: 'web_quiz_v1_q7_sharing',
        title: 'Where do you share your edited content?',
        quizFlowType: QuizFlowDisplayType.DOUBLE_COLUMN_LAST_SINGLE,
        type: QuizType.MULTI,
        resourceName: 'quizCover7',
        answers: [
            {text: 'Instagram', isSelected: false},
            {text: 'Facebook', isSelected: false},
            {text: 'YouTube', isSelected: false},
            {text: 'Pinterest', isSelected: false},
            {text: 'TikTok', isSelected: false},
            {text: 'Messengers', isSelected: false},
            {text: 'I don\'t share my content', isSelected: false, isOnlyItSelected: true}
        ]
    }
]

import React from 'react'
import {saveAuth, setLogoutId} from '../../utils/AuthUtill'
import { configs } from '../../resources/configs'
import MDSpinner from "react-md-spinner";
import {AdSource} from "../../models/Common";
import {User} from "../../models/User";
import {spinnerColor} from "../../utils/StyleUtils";
import uuid from "uuid";
import {FacebookUtils} from '../../utils/FacebookUtils';

type Props = {
    onStateChange: any
}

type State = {}

interface UserIpInfo {
    ip: string,
    country_code_iso3: string,
    city: string,
    currency: string,
}

export class Authenticator extends React.Component<Props, State> {

    constructor (props: Props) {
        super(props)
    }

    componentWillMount() {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('GET', 'https://ipapi.co/json/', true);
        xhr.onload = (e) => {
            const userIpInfo: UserIpInfo = JSON.parse(xhr.response);
            this.authorization(userIpInfo);
        };
        xhr.onerror = function (e) {
            console.error(e)
        };
        xhr.send();
    }

    authorization = (userIpInfo: UserIpInfo) => {
        //const existsUser: User | null = isAuth() ? getUser() : null;
        const existsUser: User | null = null;

        const xhr = new XMLHttpRequest();

        xhr.open("POST", configs.apiUrl + '/web-payments/authorize', true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.setRequestHeader('onelight-app-id', 'FLTR');

/*        if(existsUser) {
            xhr.setRequestHeader('onelight-web-user-id', existsUser.id);
        }*/

        xhr.onreadystatechange = () => {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    saveAuth({
                        user: JSON.parse(xhr.responseText),
                        cookies: []
                    });
                    setLogoutId(uuid.v4());
                    this.props.onStateChange(true);
                }
                else {
                    const errResp = JSON.parse(xhr.responseText);
                    console.error(errResp);
                }
            }
        }

        const facebookProps = FacebookUtils.getFacebookAdProps();

        xhr.send(JSON.stringify({
            ipCountry: userIpInfo.country_code_iso3,
            ipCity: userIpInfo.city,
            ipCurrency: userIpInfo.currency,
            ip: userIpInfo.ip,
            adSource: facebookProps.adSource ? facebookProps.adSource : AdSource.ORGANIC,
            adCampaign: facebookProps.adCampaign,
            adSet: facebookProps.adSet,
            adName: facebookProps.adName,
            fbclid: facebookProps.fbclid
        }));
    }

    render() {
        return (
            <div className='spinner-container' style={{height: '100VH'}} >
                <MDSpinner size={100} singleColor={spinnerColor}/>
            </div>
        )
    }
}

import React, {useEffect, useRef, useState} from 'react';
import {getClassName} from "../../utils/StyleUtils";
import {ProgressBar} from "../general/ProgressBar";
import {QuizResources} from "../../models/Resources";
import {useHistory} from "react-router-dom";
import {PageName} from "../../models/Common";


function updatePercent(element: any, currentValue: number, destinationValue: number, increase: number, interval: number, redirect: any) {
  const nIntervalId = setInterval(() => {
    currentValue += increase;
    element.innerText = currentValue;
    if(currentValue >= destinationValue) {
      clearInterval(nIntervalId);
      redirect()
    }
  }, interval)
}

interface StateProps {}
interface DispatchProps {}
interface OwnProps {}

const animationTime = 10000;

type Props = StateProps & DispatchProps & OwnProps;
export const QuizFinishScreen = ({}: Props) => {
  const [progressVal, setProgressLevel] = useState(0);
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    window.dataLayer.push({ 'event': PageName.QUIZ_FINISH });
  }, [])

  const refPercent = useRef(null);
  const refVideo = useRef(null);
  useEffect(() => {
    const elementPercent: any  = refPercent.current;
    const videoElem: any  = refVideo.current;

    if(elementPercent && videoElem) {
      const currentValue = Number(elementPercent.innerText);
      videoElem.play();
      if (currentValue !== progressVal) {
        const increase = currentValue > progressVal ? -1 : 1;
        const dif = Math.abs(currentValue - progressVal);
        updatePercent(elementPercent, currentValue, progressVal, increase, animationTime / dif, () => {
          history.push(`/paywall`)
        });
      }
    }
  }, [progressVal]);

  useEffect(() => {
    setProgressLevel(100);
  })
  return <div className={getClassName('quiz-finish-page-container')}>
    <div className={getClassName('quiz-finish-background-container')}/>
    <div className={getClassName('quiz-flow-finish-body')}>
      <div className={'quiz-flow-finish-before-after-container'}>
        <video src={QuizResources.quizFinishVideo.blobSrc} ref={refVideo} muted playsInline className="video-quiz">
        </video>
      </div>
      <ProgressBar
        percent={progressVal}
        style={{margin: '16px 16px 0px 16px'}}
      />
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '16px'}}>
        <div className={getClassName('quiz-flow-finish-progress-title')} >
          Analyzing collected<br/> information
        </div>
        <div className={'quiz-flow-finish-progress-percent'}><div ref={refPercent}>{0}</div>%</div>
      </div>
    </div>
  </div>;
}

import React, {useEffect, useState} from 'react';
import {QuizStartScreen} from "./QuizStartScreen";
import {fltrQuizFlow, QuizFlow, QuizFlowDisplayType, QuizType} from "../../models/Quiz";
import {getClassName} from "../../utils/StyleUtils";
import {range} from "../../utils/ArrayUtils";
import {QuizFinishScreen} from "./QuizFinishScreen";
import {QuizResources} from "../../models/Resources";
import {QuizMainScreen} from "./QuizMainScreen";

interface StateProps {
  setIsShowBack: (isShow: boolean) => void,
  isActiveFlow: boolean,
  isActiveMain: boolean,
  setIsActiveMain: (isActive: boolean) => void,
  setIsActiveFlow: (isActive: boolean) => void,
  numberActiveFlow: number,
  setNumberActiveFlow: (flowNumber: number) => void
}

interface DispatchProps {
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps

export const  QuizFlowView = ({setIsShowBack, isActiveMain, isActiveFlow, setIsActiveMain, setIsActiveFlow, numberActiveFlow, setNumberActiveFlow}: Props) => {
  const [isFinish, setIsFinish] = useState(false);
  const [quizFlow, setQuizFlow] = useState<QuizFlow[]>(JSON.parse(JSON.stringify(fltrQuizFlow)));
  const [isActiveNext, setIsActiveNext] = useState(false);

  useEffect(() => {
    const isActiveNext = quizFlow[numberActiveFlow].answers.find(answer => answer.isSelected);
    setIsActiveNext(!!isActiveNext);
  }, [quizFlow, numberActiveFlow]);

  if(isActiveMain) {
    return <QuizMainScreen
        startQuiz={() => {
          setIsActiveMain(false);
        }}
    />
  }
  if(isFinish) {
    return <QuizFinishScreen/>
  }
  if(!isActiveFlow) {
    return <QuizStartScreen
        startQuiz={() => {
          setIsActiveFlow(true);
          setIsShowBack(true);
        }}
    />;
  }
  const next = () => {
    // @ts-ignore
    window.dataLayer.push({
      event: fltrQuizFlow[numberActiveFlow].id,
      answers:  quizFlow[numberActiveFlow].answers
        .filter(answer => answer.isSelected)
        .reduce((prevAnswer, currentAnswer) => {
          return prevAnswer + currentAnswer.text + ','
        }, '').slice(0, -1)
    });
    if(numberActiveFlow === quizFlow.length - 1) {
      setIsFinish(true);
      setIsShowBack(false);
    } else {
      setNumberActiveFlow(numberActiveFlow + 1);
    }
  }

  const selectAnswer = (answerNumber: number, quizType: QuizType) => {
    const copyQuiz: QuizFlow[] = [...quizFlow];
    if(quizType === QuizType.SINGLE) {
      copyQuiz[numberActiveFlow].answers = copyQuiz[numberActiveFlow].answers.map((answer) => {
        return {...answer, ...{isSelected: false}};
      })
    }
    copyQuiz[numberActiveFlow].answers[answerNumber].isSelected = !copyQuiz[numberActiveFlow].answers[answerNumber].isSelected;
    if(copyQuiz[numberActiveFlow].answers[answerNumber].isOnlyItSelected &&
        copyQuiz[numberActiveFlow].answers[answerNumber].isSelected
    ) {
      copyQuiz[numberActiveFlow].answers = copyQuiz[numberActiveFlow].answers.map((answer) => {
        return {...answer, ...{isSelected: false}};
      })
      copyQuiz[numberActiveFlow].answers[answerNumber].isSelected = true;
    } else {
      copyQuiz[numberActiveFlow].answers = copyQuiz[numberActiveFlow].answers.map((answer) => {
        if(!answer.isOnlyItSelected) {
          return answer
        }
        return {...answer, ...{isSelected: false}};
      })
    }
    setQuizFlow(copyQuiz);

    if(quizType === QuizType.SINGLE) {
     const intervalId = setInterval(() => {
        next();
       clearInterval(intervalId);
        }, 100);
    }
  }

  const renderProgressBar = () => {
    return <div className={getClassName('quiz-flow-progress')}>
      <div className={getClassName('quiz-flow-progress-bar-container')}>
        {
          range(quizFlow.length).map(quizNumber => {
            return <div className={'quiz-flow-progress-bar' + (quizNumber <= numberActiveFlow ? '-active' : '')}></div>
          })
        }
      </div>
    </div>
  }

  const renderQuiz = (quizFlow: QuizFlow) => {
    const isRenderDouble = quizFlow.quizFlowType === QuizFlowDisplayType.DOUBLE_COLUMN_LAST_SINGLE;
    return <div className={getClassName('quiz-flow-body')} id={`quiz-${numberActiveFlow}`}>
        <div className={getClassName('quiz-flow-title')}>{quizFlow.title}</div>
        <div className={'quiz-flow-answers-container'}>
          {quizFlow.answers.map((answer, answerNumber) => {
            return <div className={getClassName('quiz-flow-answer') + (answer.isSelected ? '-active' : '')}
              key={answer.text} onClick={() => {selectAnswer(answerNumber, quizFlow.type)}}
                        style={{width: isRenderDouble && answerNumber !== quizFlow.answers.length -1 ? '47%' : '100%'}}
            >
              {answer.text}
            </div>
          })}
        </div>
      {quizFlow.type === QuizType.MULTI ? <button
          className={getClassName('quiz-flow-question-next')}
          disabled={!isActiveNext}
          onClick={() => {if(isActiveNext){next()}}}
      >
        Next
      </button> : null}
    </div>
  }

  const renderCoverImgs = (quizFlow: QuizFlow) => {
    return <div className={getClassName('quiz-flow-imgs-container')}>
        <img className={getClassName('quiz-flow-img')}
                           src={QuizResources[quizFlow.resourceName].blobSrc}
        />
    </div>
  }

  return <div className={getClassName('quiz-flow-container')}>
    {renderProgressBar()}
    {renderQuiz(quizFlow[numberActiveFlow])}
    {renderCoverImgs(quizFlow[numberActiveFlow])}
  </div>;
};
